import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Fortmatic from "fortmatic";

export const providerOptions = {
  // binancechainwallet: {
  //   package: true,
  // },
  // walletlink: {
  //   package: CoinbaseWalletSDK,
  //   options: {
  //     appName: "Web 3 Modal Demo",
  //     infuraId: {
  //       // 4: "https://rinkeby.infura.io/v3/d6268f2fdeed46cfbe4a412d6607cace",
  //       infuraId: "765d4237ce7e4d999f706854d5b66fdc",
  //     },
  //   },
  // },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        97: "https://data-seed-prebsc-1-s1.binance.org:8545",
        56: "https://bsc-dataseed.binance.org/",
      },
      chainId: 97,
      network: "binance",
    },
  },
  // fortmatic: {
  //   package: Fortmatic,
  //   options: {
  //     key: "pk_test_391E26A3B43A3350",
  //   },
  // },
};
