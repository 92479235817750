// import { getweb3, walletConnectModalInit } from '../getweb3';
import React from "react";
// import { chainId } from "../config";
// import useWallet_model from "../web3modal";
import Web3Modal from "web3modal";
import { providerOptions } from "../providerOptions";
import Web3 from "web3";
import { ethers, providers } from "ethers";

let web3 = new Web3(
  new Web3.providers.HttpProvider("https://bsc-testnet.public.blastapi.io")
);

const chainId = "0x61";
async function getNetworkId() {
  try {
    let web3Modal = new Web3Modal({
      network: "testnet",
      cacheProvider: true,
      providerOptions,
    });
    let provider = await web3Modal.connect();
    web3 = new Web3(provider);
    const resp = await web3.eth.net.getId();
    return resp;
    // return await window.ethereum.request({ method: "eth_chainId" });
  } catch (error) {
    return 1;
  }
}

const getWeb3 = async (isAuthenticate) => {
  if (web3 !== null) {
    let web3Data = {
      isLoggedIn: false,
      accounts: [],
    };
    try {
      let web3Modal = new Web3Modal({
        network: "testnet",
        cacheProvider: true,
        providerOptions,
      });
      let provider = await web3Modal.connect();
      web3 = new Web3(provider);
      const responseData = await web3.eth.getAccounts();
      const resp = await web3.eth.net.getId();
      console.log("resp", resp, "responseData", responseData);
      if (responseData.length && resp === chainId) {
        web3Data.accounts = responseData;
        // if (isAuthenticate || localStorage.getItem('liquidToken')) {
        // if (isAuthenticate) {
        web3Data.isLoggedIn = true;
        // }
        return web3Data;
      } else {
        return web3Data;
      }
    } catch {
      return web3Data;
    }
  }
};

const enabledWalletConnect = async () => {
  try {
    // await walletConnectModalInit()
    // await web3Loading();
    const resp = await getWeb3();
    return resp;
  } catch (error) {
    if (error.code === -32002) {
      return {
        isLoggedIn: false,
        accounts: [],
      };
    }
    return {
      isLoggedIn: false,
      accounts: [],
    };
  }
};

const enableMetamask = async () => {
  try {
    await window.ethereum.request({ method: "eth_requestAccounts" });
    // localStorage.setItem('isDisconnect', '0')
    const resp = await getWeb3();
    return resp;
  } catch (error) {
    if (error.code === -32002) {
      return {
        error: true,
        code: error.code,
        msg: error.message,
        isLoggedIn: false,
        accounts: [],
      };
    }
    if (error.code === 4001) {
      return {
        error: true,
        code: error.code,
        msg: error.message,
        isLoggedIn: false,
        accounts: [],
      };
    }
    return {
      error: true,
      code: error.code,
      msg: error.message,
      isLoggedIn: false,
      accounts: [],
    };
  }
};

let web3Data = {
  isLoggedIn: false,
  accounts: [],
};
const fetchAccountData = async (provider) => {
  console.log("called");
  try {
    console.log("called 2", provider);
    window.provider = provider;
    console.log("called 3", window.provider);

    web3 = new Web3(provider);
    console.log("web3 3 provider in web3modal", web3);
    localStorage.setItem("modalProvider", 1);
    //get address
    const accounts = await web3.eth.getAccounts();
    console.log("accounts", accounts);
    web3Data.isLoggedIn = true;
    web3Data.accounts = accounts;
    web3Data.address = accounts[0];

    // //get network id

    const chainid = await web3.eth.net.getId();
    return web3Data;
  } catch (err) {
    return web3Data;
    // console.log(err);
  }
};

// const refreshAccountData = async (provider, dispatch) => {
//   await fetchAccountData(provider);
//   return dispatch({ type: "WEB3DATA", data: web3Data });
// };

const enableWeb3Modal = async (dispatch) => {
  let _provider;
  try {
    let web3Modal = new Web3Modal({
      network: "testnet",
      cacheProvider: true,
      providerOptions,
    });
    _provider = await web3Modal.connect();
    await fetchAccountData(_provider);
    if (_provider) {
      // Subscribe to accounts change
      _provider.on("accountsChanged", async (accounts) => {
        await fetchAccountData(_provider);
        console.log("accountsChanged", web3Data);
        return dispatch({ type: "WEB3DATA", data: web3Data });
      });
      // Subscribe to chainId change
      _provider.on("chainChanged", (chainId) => {
        fetchAccountData(_provider);
        console.log("chainChanged");
      });

      // Subscribe to networkId change
      _provider.on("networkChanged", (networkId) => {
        fetchAccountData(_provider);
        console.log("networkChanged");
      });
      // await refreshAccountData(_provider, dispatch);
    }
    return web3Data;
  } catch (e) {
    console.log("Could not get a wallet connection", e);
    // return;
  }
  console.log("_provider", _provider);

  // return web3Data;
};

const disableWeb3Modal = async () => {
  let web3Data = {
    isLoggedIn: false,
    accounts: [],
  };
  let web3Modal = new Web3Modal({
    network: "testnet",
    cacheProvider: true,
    providerOptions,
  });
  await web3Modal.clearCachedProvider();
  localStorage.removeItem("modalProvider");
  return web3Data;
};

export const web3Services = {
  enableWeb3Modal,
  enabledWalletConnect,
  enableMetamask,
  getNetworkId,
  getWeb3,
  web3,
  disableWeb3Modal,
};
