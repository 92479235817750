import { services } from "../../services";
let networkId = 97;
// async function fetchNetworkId() {
//   networkId = await services.getNetworkId();
// }
// fetchNetworkId();

// 7: "0xb97Da845F01dC7d12606985505904bf1948228bA",
// 14: "0x1EE137E5699267A233f2a876AED8ecc77c0A03a4",
// 30: "0xf5A397B7a18e92a21504fBcaBD8be3b0dF462daF",
// 60: "0x9Ec350f83caB91094CcB97082743210Dc194E717",
function getContractAddresses() {
  // console.log(networkId);
  if (networkId === "0x61" || +networkId === 97)
    return {
      pancakeLP: "0x2789D698B222a3b90BEB31C175A508d75Ff35b4B",
      seedifyToken: "0x510601cb8Db1fD794DCE6186078b27A5e2944Ad6", //'0xd82fE6bf9dCE7EC68a8F455f20f04007cBF845e7',
      7: "0x32662CEf710Ef6b484D3D0FF3E42f97576005ef8",
      // 7: '0x9a3C8e8402220a228ad1fB99a669f98e79B20ffb',
      14: "0x20D3a589F5486b39a9114373F9e65cbc5C541fB6",
      30: "0xcAd6299FEc2a750637fBf2a09B8A519e03bB21Bd",
      60: "0x76eac6aeBe65eadc7e0d4BbAe17d1e38f1837c3a",
      90: "0x9a3C8e8402220a228ad1fB99a669f98e79B20ffb",
      180: "0x5D4F8e9f59d3EEa9Bd6200573149D3ca71e1F02b",
      farmingContract: {
        pancakeSwap: {
          contract: "0x6b23fad324e12a177c76830e9150d6be648db6e6",
          lpToken: "0x2789D698B222a3b90BEB31C175A508d75Ff35b4B",
        },
        bakerySwap: {
          contract: "0x5b40238E06B2a8bEef5de1C4AD329E52902Eb8BB",
          lpToken: "0xc2Eed0F5a0dc28cfa895084bC0a9B8B8279aE492",
        },
      },
    };
  else if (+networkId === 56 || networkId === "0x38")
    return {
      pancakeLP: "0x74fA517715C4ec65EF01d55ad5335f90dce7CC87",
      seedifyToken: "0x477bc8d23c634c154061869478bce96be6045d12",
      7: "0xb667c499b88AC66899E54e27Ad830d423d9Fba69",
      14: "0x027fC3A49383D0E7Bd6b81ef6C7512aFD7d22a9e",
      30: "0x8900475BF7ed42eFcAcf9AE8CfC24Aa96098f776",
      60: "0x66b8c1f8DE0574e68366E8c4e47d0C8883A6Ad0b",
      90: "0x5745b7E077a76bE7Ba37208ff71d843347441576",
      180: "0xf420F0951F0F50f50C741f6269a4816985670054",
      farmingContract: {
        pancakeSwap: {
          contract: "0x6b23fad324e12a177c76830e9150d6be648db6e6",
          lpToken: "0x74fa517715c4ec65ef01d55ad5335f90dce7cc87",
        },
        bakerySwap: {
          contract: "0x1544be2dC66eaE3E91d983c6D27c9CB1CDe74AcF",
          lpToken: "0x782f3f0d2b321D5aB7F15cd1665B95EC479Dcfa5",
        },
      },
      oldFarmingContract: {
        pancakeSwap: {
          contract: "0x7439bCF0B97ecd7f3A11c35Cc2304F01Eaf04fC0",
          lpToken: "0x74fa517715c4ec65ef01d55ad5335f90dce7cc87",
        },
        bakerySwap: {
          contract: "0x1272B728B8964e75786c0f1772033719C0Fa5eAc",
          lpToken: "0x782f3f0d2b321D5aB7F15cd1665B95EC479Dcfa5",
        },
      },
    };
  else
    return {
      pancakeLP: "0x74fA517715C4ec65EF01d55ad5335f90dce7CC87",
      seedifyToken: "0x477bc8d23c634c154061869478bce96be6045d12",
      7: "0xb667c499b88AC66899E54e27Ad830d423d9Fba69",
      14: "0x027fC3A49383D0E7Bd6b81ef6C7512aFD7d22a9e",
      30: "0x8900475BF7ed42eFcAcf9AE8CfC24Aa96098f776",
      60: "0x66b8c1f8DE0574e68366E8c4e47d0C8883A6Ad0b",
      90: "0x5745b7E077a76bE7Ba37208ff71d843347441576",
      180: "0xf420F0951F0F50f50C741f6269a4816985670054",
      farmingContract: {
        pancakeSwap: {
          contract: "0x6b23fad324e12a177c76830e9150d6be648db6e6",
          lpToken: "0x74fa517715c4ec65ef01d55ad5335f90dce7cc87",
        },
        bakerySwap: {
          contract: "0x1544be2dC66eaE3E91d983c6D27c9CB1CDe74AcF",
          lpToken: "0x782f3f0d2b321D5aB7F15cd1665B95EC479Dcfa5",
        },
      },
      oldFarmingContract: {
        pancakeSwap: {
          contract: "0x7439bCF0B97ecd7f3A11c35Cc2304F01Eaf04fC0",
          lpToken: "0x74fa517715c4ec65ef01d55ad5335f90dce7cc87",
        },
        bakerySwap: {
          contract: "0x1272B728B8964e75786c0f1772033719C0Fa5eAc",
          lpToken: "0x782f3f0d2b321D5aB7F15cd1665B95EC479Dcfa5",
        },
      },
    };

  // return {
  //   binance: "0xE5B282A53dE55C4732707B9b100FD41b001B83A8",
  //   polygon: "0xC59fe24351AdAf9f86240c1B7D6a1856e97bd36b",
  //   ethereum: "0xAd59AaA819FEED862Fa11672f7BdCD691dAe65ED",
  //   avalanche: "0xEA71813ef9AcB656BC98C9C3ba1fdd761536B0c9",
  // };
}
export default getContractAddresses;
